import React, { createContext, useState } from 'react';
import { ApolloProvider } from '@apollo/client';
import client from '@apolloClient';
import { isClient } from '@helpers/isClient';
export const SiteContext = createContext();

const Provider = (props) => {
  let ancestors = null;
  let title = null;
  let currentLanguage = 'en';

  const [user, setUser] = useState({
    username: '',
    email: '',
  });

  const [isLoggedIn, setIsLoggedIn] = useState(
    isClient && !!localStorage.getItem('token'),
  );

  const [nearestLocation, setLocation] = useState({
    id: '',
    link: '',
  });

  const [clientHeight, setClientHeight] = useState('');

  return (
    <ApolloProvider client={client}>
      <SiteContext.Provider
        value={{
          ancestors,
          title,
          currentLanguage,
          nearestLocation,
          setLocation,
          isLoggedIn,
          setIsLoggedIn,
          clientHeight,
          setClientHeight,
          user,
          setUser,
        }}
      >
        {props.children}
      </SiteContext.Provider>
    </ApolloProvider>
  );
};

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ element }) => <Provider>{element}</Provider>;
