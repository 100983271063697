import ReactDOM from 'react-dom/client';

/**
 * Tailwind
 */
import './src/styles/tailwind.css';

/**
 * SCSS
 */
import '@styles/main.scss';

/**
 * Provider
 * See: https://www.digitalocean.com/community/tutorials/gatsbyjs-state-management-in-gatsby
 */
import Provider from './src/store/Provider';
import { isClient } from './src/helpers/isClient';

/**
 * Removing the no-js class for body
 * useful with e.g. FadeInBox and FadeInUpBox
 * See: https://curtistimson.co.uk/post/gatsbyjs/add-body-class-gatsbyjs-fouc/
 */

export const onClientEntry = () => {
  if (isClient) {
    window.addEventListener('load', () => {
      document.body.className = document.body.className.replace(
        /\bno-js\b/,
        '',
      );
    });

    if ('scrollRestoration' in window.history) {
      window.history.scrollRestoration = 'manual';
    }
  }
};

export const wrapRootElement = Provider;

export const replaceHydrateFunction = () => {
  return (element, container) => {
    const root = ReactDOM.createRoot(container);
    root.render(element);
  };
};

// Overwrites gatsby scroll restoration function and disables it.
export const shouldUpdateScroll = () => {
  return false;
};
