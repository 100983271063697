exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-us-index-jsx": () => import("./../../../src/pages/about-us/index.jsx" /* webpackChunkName: "component---src-pages-about-us-index-jsx" */),
  "component---src-pages-blog-index-jsx": () => import("./../../../src/pages/blog/index.jsx" /* webpackChunkName: "component---src-pages-blog-index-jsx" */),
  "component---src-pages-login-jsx": () => import("./../../../src/pages/login.jsx" /* webpackChunkName: "component---src-pages-login-jsx" */),
  "component---src-pages-register-jsx": () => import("./../../../src/pages/register.jsx" /* webpackChunkName: "component---src-pages-register-jsx" */),
  "component---src-templates-bonus-page-jsx": () => import("./../../../src/templates/BonusPage.jsx" /* webpackChunkName: "component---src-templates-bonus-page-jsx" */),
  "component---src-templates-campaign-page-jsx": () => import("./../../../src/templates/CampaignPage.jsx" /* webpackChunkName: "component---src-templates-campaign-page-jsx" */),
  "component---src-templates-get-help-page-jsx": () => import("./../../../src/templates/GetHelpPage.jsx" /* webpackChunkName: "component---src-templates-get-help-page-jsx" */),
  "component---src-templates-home-page-jsx": () => import("./../../../src/templates/HomePage.jsx" /* webpackChunkName: "component---src-templates-home-page-jsx" */),
  "component---src-templates-page-jsx": () => import("./../../../src/templates/Page.jsx" /* webpackChunkName: "component---src-templates-page-jsx" */),
  "component---src-templates-policy-pages-jsx": () => import("./../../../src/templates/PolicyPages.jsx" /* webpackChunkName: "component---src-templates-policy-pages-jsx" */)
}

